// ** React Imports
import { useState, SyntheticEvent, Fragment, useEffect } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem';

// ** Custom Components Imports
import { VigoTypography } from 'src/@vigo/vigo-typography';
import { VigoButton } from 'src/@vigo/vigo-button';
import { Incognito } from 'mdi-material-ui';
import { useAuth } from 'react-oidc-context';
import { Products, useGetProductLevel } from 'src/@core/utils/getProductLevel';
import { Stack, keyframes } from '@mui/material';
import { useSettings } from 'src/@core/hooks/useSettings';
import router from 'next/router';
import { getRolesAsync } from 'src/services/identity';
import { useQuery } from '@tanstack/react-query';
import { VigoSelectBox } from 'src/@vigo/vigo-select';

// ** Styled Menu component
const Menu = styled(MuiMenu)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    minWidth: 380,
    maxWidth: 600,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}));

export const jump: any = keyframes`
0% {
  color: #c3c6d4;
  top: 12px;
}
1.25% {
    top: -17px;
}
2.5% {
    top: 8px;
}
3.5% {
    top: -3px;
}
4.5% {
    color: #c3c6d4;
    top: 0;
}
7.5% {
    color: #c3c6d4;
}
100% {
    color: #c3c6d4;
    top: 0;
}
`;

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}));

const ImpersonationDropdown = () => {
  const prod = useGetProductLevel();
  // ** States
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);

  const { user } = useAuth();

  const { settings, saveSettings } = useSettings();
  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  // get logged in tenant
  let impersonatedProduct = prod;
  let impersonatedCompany = '';
  let impersonatedRole = '';

  // check if we are already impersonating a tenant
  const loggedInUserId = user?.profile.tenantUserId as string;
  const fromSession = window.sessionStorage.getItem(`impersonate-${loggedInUserId}`);
  if (fromSession) {
    impersonatedProduct = JSON.parse(fromSession).productLevel;
    impersonatedCompany = JSON.parse(fromSession).companyName;
    impersonatedRole = JSON.parse(fromSession).roleId;
  }

  const [selectedRole, setSelectedRole] = useState<string>('');

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const { data: roles } = useQuery({
    queryKey: ['userroles', impersonatedProduct],
    queryFn: async () => {
      const res = await getRolesAsync();

      return res.data;
    },
    refetchOnWindowFocus: false
  });

  const removeSettings = () => {
    const id = user?.profile.tenantUserId as string;
    // remove impersonated impersonation
    window.sessionStorage.removeItem(`impersonate-${id}`);
    saveSettings({ ...settings, isImpersonating: false });

    setAnchorEl(null);

    router.push('/dashboards/Home');
  };

  const navigateToRouteFinder = () => {
    setAnchorEl(null);

    router.push('/dashboards/routefinder');
  };

  useEffect(() => {
    if (fromSession) {
      setSelectedRole(impersonatedRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromSession]);

  return (
    <Fragment>
      <IconButton
        size="large"
        color="inherit"
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        sx={{ animationDelay: '1s', animation: `${jump} 8s infinite ease-in-out` }}
        aria-controls="customized-menu">
        <Incognito fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem disableRipple>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <VigoTypography value="Impersonation" sx={{ fontWeight: 600 }} />
          </Box>
        </MenuItem>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
          <Stack justifyContent="center" alignItems="center" gap="6px" sx={{ width: '100%', wordBreak: 'break-word' }}>
            <VigoTypography
              sx={{ fontSize: '1.25rem', fontWeight: '600' }}
              disableTranslation
              value={impersonatedCompany}
            />

            <VigoTypography
              disableTranslation
              sx={{ fontSize: '0.95rem' }}
              value={impersonatedProduct === Products.MyPortal ? 'Connect' : 'Flow'}
            />
            <VigoSelectBox
              name="impersonatedRole"
              datasource={roles ? roles : []}
              label="Impersonated Role"
              displayName="description"
              value={selectedRole}
              fullWidth
              required
              variant="standard"
              onChange={event => {
                if (event.target.value != '') {
                  setSelectedRole(event.target.value);
                  // check if we are already impersonating a tenant
                  const loggedInUserId = user?.profile.tenantUserId as string;
                  const fromSession = window.sessionStorage.getItem(`impersonate-${loggedInUserId}`);

                  if (fromSession) {
                    const toSession = JSON.parse(fromSession);
                    toSession.roleId = event.target.value;
                    window.sessionStorage.setItem(`impersonate-${loggedInUserId}`, JSON.stringify(toSession));
                  }
                }
              }}
            />
          </Stack>
        </Box>
        {prod === Products.Flow && (
          <MenuItem
            disableRipple
            sx={{ py: 3.5, borderBottom: 0, borderTop: theme => `1px solid ${theme.palette.divider}` }}>
            <VigoButton
              name="routefinder"
              value="Route Finder"
              variant="contained"
              fullWidth
              onClick={navigateToRouteFinder}
            />
          </MenuItem>
        )}
        <MenuItem
          disableRipple
          sx={{
            py: 3.5,
            borderBottom: 0,
            borderTop: prod === Products.MyPortal ? theme => `1px solid ${theme.palette.divider}` : undefined
          }}>
          <VigoButton
            name="readnotifications"
            value="Cancel Impersonation"
            variant="contained"
            fullWidth
            onClick={removeSettings}
          />
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default ImpersonationDropdown;
