import { Box, InputAdornment, Stack } from '@mui/material';
import { FilterVariant } from 'mdi-material-ui';
import { VigoTextInput } from 'src/@vigo/vigo-textinput';
import { VigoAttachment } from '../..';
import { useState } from 'react';
import PreviewModal from './PreviewModal';
import { FileAttachment } from '../FileAttachment';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import dynamic from 'next/dynamic';
import { FileThumbnailProps } from './FileThumbnail';

const FileThumbnail = dynamic<FileThumbnailProps>(() => import('./FileThumbnail').then(mod => mod.FileThumbnail), {
  ssr: false
});

interface FilePreviewProps {
  files: VigoAttachment[];
  viewMode: 'attach' | 'view' | undefined;
  entityTypeId: string;
  entityId: string;
  attachmentTypes: any[];
  disableUpload?: boolean;
  handleDeleteFile: (file: any, index: number) => void;
  handleUploadSuccess: (file: any) => void;
  handleToggleFileVisibility?: (file: any) => void;
  readOnly?: boolean;
  showFilter?: boolean;
  partnerId?: string;
  allowToggleFileVisibility: boolean;
}

const FilePreview = ({
  files,
  viewMode,
  entityId,
  entityTypeId,
  attachmentTypes,
  disableUpload = false,
  handleDeleteFile,
  handleUploadSuccess,
  handleToggleFileVisibility,
  readOnly = false,
  showFilter = false,
  partnerId,
  allowToggleFileVisibility
}: FilePreviewProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [filter, setFilter] = useState('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const docTypeMenuOpen = Boolean(anchorEl);
  const [listParent] = useAutoAnimate();
  const [thumbsParent] = useAutoAnimate();

  const openFileTypeMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  if (files.length < 1) {
    return null;
  }

  return (
    <Box>
      {showFilter && files.length > 0 && (
        <Box sx={{ margin: '5px' }}>
          <VigoTextInput
            name="uploadedFilesFilterTextBox"
            placeholder="Filter"
            fullWidth
            onChange={e => setFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterVariant />
                </InputAdornment>
              )
            }}
          />
        </Box>
      )}

      {viewMode === 'attach' && (
        <Box
          ref={listParent}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            marginBottom: '6px',
            overflow: 'hidden'
          }}>
          {files
            .filter(file => file?.name?.toLowerCase()?.includes(filter?.toLowerCase()))
            .map((file: VigoAttachment, index: number) => (
              <FileAttachment
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                file={file}
                index={index}
                entityId={entityId}
                entityTypeId={entityTypeId}
                attachmentTypes={attachmentTypes}
                openFileViewer={() => setSelectedFile(file)}
                handleDeleteFile={() => handleDeleteFile(file, index)}
                handleUploadSucess={() => handleUploadSuccess(file)}
                handleToggleFileVisibility={() => handleToggleFileVisibility?.(file)}
                disableUpload={disableUpload}
                readOnly={readOnly}
                partnerId={partnerId}
                allowToggleFileVisibility={allowToggleFileVisibility}
              />
            ))}
        </Box>
      )}

      {viewMode === 'view' && (
        <Stack ref={thumbsParent} direction="row" flexWrap="wrap" gap={3} p="15px">
          {files
            .filter(file => file?.name?.toLowerCase()?.includes(filter?.toLowerCase()))
            .map((file: VigoAttachment, index: number) => (
              <FileThumbnail
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                file={file as VigoAttachment}
                index={index}
                openFileViewer={() => setSelectedFile(file)}
                openFileTypeMenu={openFileTypeMenu}
                handleDeleteFile={() => handleDeleteFile(file, index)}
                docTypeMenuOpen={docTypeMenuOpen}
                readOnly={readOnly}
              />
            ))}
        </Stack>
      )}

      <PreviewModal file={selectedFile} fileViewerOpen={!!selectedFile} closeFileViewer={() => setSelectedFile(null)} />
    </Box>
  );
};

export default FilePreview;
