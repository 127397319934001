/**
 * Base functions for building queries.
 */

export type CustomResponseModifierType<T> = (data: T) => any | undefined;

export interface QueryProps<T, P> {
  enabled?: boolean;
  onSuccess?: (res: T) => void;
  onError?: (error: Error) => void;
  onSettled?: () => void;
  initialData?: any;
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
  staleTime?: number;
  customResponseModifier?: CustomResponseModifierType<T>; // Function to modify response data in the query function.
  queryKeyOverride?: any[];
  params?: P;
  retry?: any;
}

export interface QueryFnProps<T> {
  serviceFn: (x?: any) => any;
  customResponseModifier?: CustomResponseModifierType<T>;
}

/**
 *  Base query function
 */
export const baseQueryFn = async <T>({ serviceFn, customResponseModifier = undefined }: QueryFnProps<T>) => {
  const res = await serviceFn();

  if (!customResponseModifier) {
    return res.data;
  }

  return customResponseModifier(res.data);
};
