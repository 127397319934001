// ** React Imports
import { ReactNode } from 'react';

// ** Next Imports
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

// ** Store Imports

// ** Loader Import
import NProgress from 'nprogress';

// ** Emotion Imports
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';

// ** Config Imports
import 'src/configs/i18n';
import { defaultACLObj } from 'src/configs/acl';
import themeConfig from 'src/configs/themeConfig';
import getConfig from 'next/config';

// ** Fake-DB Import
import 'src/@fake-db';

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout';
import ThemeComponent from 'src/@core/theme/ThemeComponent';

// import WindowWrapper from 'src/@core/components/window-wrapper';
import AclGuard from 'src/authentication/AclGuard';
import GuestGuard from 'src/authentication/GuestGuard';

// ** Contexts
import { AuthProvider } from 'react-oidc-context';
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext';

import { VigoPageProvider } from 'src/@core/context/vigoPageContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// ** Styled Components

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache';

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** Global css styles
import '../../styles/globals.css';
import AuthGuard from 'src/authentication/AuthGuard';
import 'src/@core/styles/libs/sweet-alerts-2/toastStyles.css';
import 'src/@core/styles/libs/sweet-alerts-2/confirmDialogStyles.css';
import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/styles';

// import { Forklift } from 'mdi-material-ui';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SplitViewProvider } from 'src/context/splitViewContextProvider';
import { BreadcrumbsProvider } from 'src/context/breadcrumbsContextProvider';
import { useConsoleMessage } from 'src/@core/hooks/useConsoleMessage';
import { IdleTimer } from 'src/@core/components/idle-timer';

//sortable tree custome styles
import 'src/@vigo/vigo-treeview/themes/placeholder-renderer-default.css';
import 'src/@vigo/vigo-treeview/themes/node-renderer-default.css';
import 'src/@vigo/vigo-treeview/themes/tree-node.css';

// Permissions
import 'src/@vigo/vigo-permissionstree/permissions.css';

// Darkmode grid
import 'src/@vigo/vigo-datagridNew/datagridDarkmode.css';
import 'src/@vigo/vigo-datagridNew/datagridLightmode.css';

// DateRange darkmode
import 'src/@vigo/vigo-daterange/daterangeDarkmode.css';

// HTML Editor
import 'src/@vigo/vigo-htmleditor/html-editor.css';

//Hot Toast
import { Toaster } from 'react-hot-toast';
import VigoSpinner from 'src/@vigo/vigo-spinner';
import { AppBarContextProvider } from 'src/@core/context/appBarContext';

import { Provider } from 'react-redux';
import { store } from 'src/store';

import 'react-device-frameset/styles/marvel-devices.min.css';
import { getShortUrlKey } from 'src/services/shorturl';
import { Logtail } from '@logtail/browser';
import { LoggingContextProvider } from 'src/@core/context/loggingContext';
import ErrorBoundaryWrapper from 'src/@core/components/ErrorBoundaryWrapper';
import SocketClient from 'src/@core/components/SocketClient';
import { GridRefProvider } from 'src/@core/context/gridRefContext';
import { NewGridRefProvider } from 'src/@core/context/newGridRefContext';

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

type GuardProps = {
  authGuard: boolean;
  guestGuard: boolean;
  children: ReactNode;
  hideSpinner: boolean;
};

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start();
  });
  Router.events.on('routeChangeError', () => {
    NProgress.done();
  });
  Router.events.on('routeChangeComplete', () => {
    NProgress.done();
  });
}

const { publicRuntimeConfig } = getConfig();

const Guard = ({ children, authGuard, guestGuard, hideSpinner }: GuardProps) => {
  const router = useRouter();

  if (guestGuard) {
    return (
      <GuestGuard
        hideSpinner={hideSpinner || router.route === '/404'}
        fallback={<VigoSpinner name="appGuestGuardSpinner" />}>
        {children}
      </GuestGuard>
    );
  } else if (!guestGuard && !authGuard) {
    return <div>{children}</div>;
  } else {
    return (
      <AuthGuard
        hideSpinner={hideSpinner || router.route === '/404'}
        fallback={<VigoSpinner name="appAuthGuardSpinner" />}>
        {children}
      </AuthGuard>
    );
  }
};

async function getShortUrl(key: string): Promise<string> {
  return (await getShortUrlKey(key)).data;
}

/** TanStack Query Client */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

/** BetterStack Logtail */
const { LoggingSource } = publicRuntimeConfig;
const logtail = LoggingSource ? new Logtail(LoggingSource) : null;

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const router = useRouter();

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>);

  const setConfig = Component.setConfig ?? undefined;

  const authGuard = Component.authGuard ?? true;

  const guestGuard = Component.guestGuard ?? false;

  const aclAbilities = Component.acl ?? defaultACLObj;

  let origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const href = typeof window !== 'undefined' && window.location.href ? window.location.href : '';

  if (!origin.toString().toLowerCase().includes('localhost')) {
    origin = origin.toString().replace('http://', 'https://');
  }

  useConsoleMessage();

  if (href.toLowerCase().includes(publicRuntimeConfig.TinyDomain)) {
    // Get actual key from service
    const key = href.substring(href.substring(0, href.lastIndexOf('/')).lastIndexOf('/') + 1).slice(0, -1);
    getShortUrl(key)
      .then(val => {
        if (val !== '') {
          return router.push(val);
        } else {
          return router.push('/Tracking404');
        }
      })
      .catch(err => {
        console.log(err);

        return router.push('/Tracking404');
      });
  } else {
    const newredirect = origin + '/signin';
    const newredirectlogout = origin;

    const oidcConfig = {
      authority: publicRuntimeConfig.Authority,
      client_id: publicRuntimeConfig.ClientId,
      redirect_uri: newredirect,
      response_type: 'code',
      scope: 'openid profile vigocloud_api_scope',
      post_logout_redirect_uri: newredirectlogout,
      acr_values: publicRuntimeConfig.Domain
    };

    return (
      <>
        <Provider store={store}>
          <CacheProvider value={emotionCache}>
            <LoggingContextProvider logger={logtail}>
              <Head>
                <title>{`${themeConfig.templateName}`}</title>
                <meta name="description" content={`${themeConfig.templateName}`} />
                <meta name="keywords" content="Vigo Scalable Logistics Platform" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
              </Head>
              {/* @ts-ignore */}
              <AuthProvider {...oidcConfig}>
                <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
                  <SettingsConsumer>
                    {({ settings }) => {
                      return (
                        <ThemeComponent settings={settings}>
                          <QueryClientProvider client={queryClient}>
                            <Guard
                              authGuard={authGuard}
                              guestGuard={guestGuard}
                              hideSpinner={href.includes('/Tracking404')}>
                              <AclGuard aclAbilities={aclAbilities} guestGuard={guestGuard}>
                                <SocketClient>
                                  <AppBarContextProvider>
                                    <BreadcrumbsProvider>
                                      <SplitViewProvider>
                                        <GridRefProvider>
                                          <NewGridRefProvider>
                                            <VigoPageProvider>
                                              {getLayout(
                                                <ErrorBoundaryWrapper>
                                                  <Component {...pageProps} />
                                                </ErrorBoundaryWrapper>
                                              )}
                                            </VigoPageProvider>
                                          </NewGridRefProvider>
                                        </GridRefProvider>
                                        <IdleTimer />
                                      </SplitViewProvider>
                                    </BreadcrumbsProvider>
                                  </AppBarContextProvider>
                                </SocketClient>
                              </AclGuard>
                            </Guard>
                            <ReactQueryDevtools
                              initialIsOpen={false}
                              position="bottom-left"
                              toggleButtonProps={{ style: { left: '170px', bottom: '5px' } }}
                            />
                          </QueryClientProvider>
                        </ThemeComponent>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </AuthProvider>
              <Toaster position="top-right" />
            </LoggingContextProvider>
          </CacheProvider>
        </Provider>
      </>
    );
  }
};

export default App;
