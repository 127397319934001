import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

export type PermissionType = 'CanCreate' | 'CanRead' | 'CanUpdate' | 'CanDelete';

export type UiComponentPermissions = Record<PermissionType, boolean>;

export interface UiFlowPermissionsDataState {
  uiFlowId: string;
  uiFlowDescription: string;
  dtoId: string;
  uiComponentPermissions: Record<string, UiComponentPermissions>;
}

const initialState: UiFlowPermissionsDataState = {
  uiFlowId: '',
  uiFlowDescription: '',
  dtoId: '',
  uiComponentPermissions: {}
};

export const uiFlowPermissionsSlice: Slice<UiFlowPermissionsDataState> = createSlice({
  name: 'uiFlowPermissionsData',
  initialState: initialState,
  reducers: {
    setActiveUiFlowPermissions: (state, action: PayloadAction<UiFlowPermissionsDataState>) => {
      state.uiFlowId = action.payload.uiFlowId;
      state.uiFlowDescription = action.payload.uiFlowDescription;
      state.dtoId = action.payload.dtoId;
      state.uiComponentPermissions = action.payload.uiComponentPermissions;
    },
    resetActiveUiFlowPermissions: () => initialState
  }
});

export const { setActiveUiFlowPermissions, resetActiveUiFlowPermissions } = uiFlowPermissionsSlice.actions;

export default uiFlowPermissionsSlice.reducer;
