import { Box, IconButton, Modal, Typography } from '@mui/material';
import { VigoAttachment } from '../..';
import { Close, ImageOff, React } from 'mdi-material-ui';
import { AttachmentDto } from 'src/models/attachment';
import { useState } from 'react';
import RenderTiff from './RenderTiff';
import styles from '../../vigofiledrop.module.css';
import dynamic from 'next/dynamic';
import { VigoPdfViewerProps } from 'src/@vigo/vigo-pdfviewer';

const VigoPdfViewer = dynamic<VigoPdfViewerProps>(
  () => import('src/@vigo/vigo-pdfviewer').then(mod => mod.VigoPdfViewer),
  {
    ssr: false
  }
);

interface PreviewModalProps {
  file: any;
  fileViewerOpen: boolean;
  closeFileViewer: () => void;
}

const PreviewModal = ({ file, fileViewerOpen, closeFileViewer }: PreviewModalProps) => {
  const [pdf, setPdf] = useState<string>('');

  const buildPreview = (file: VigoAttachment) => {
    /**
     * File is of type TIFF
     */
    if ((file as VigoAttachment)?.type == 'image/tiff' || (file as AttachmentDto)?.fileType == 'image/tiff') {
      return <RenderTiff file={file} />;
    }

    /**
     * File is of PDF type
     * - check property 'type' if VigoAttachment, or 'fileType' if AttachmentDto
     */
    if ((file as VigoAttachment)?.type == 'application/pdf' || (file as AttachmentDto)?.fileType == 'application/pdf') {
      const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });

      (async () => {
        const base64 = await toBase64(file);

        setPdf((base64 as string).substring(28));
      })();

      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > *  ': { width: '100% !important', height: '100% !important' },
            overflow: 'auto'
          }}>
          <VigoPdfViewer file={file} fileBase64={pdf} fileName={file.name} fullScreen={false} />
        </Box>
      );
    }

    /**
     * File is of image type
     */
    if ((file as VigoAttachment)?.type?.match(/image\/.*/) || (file as AttachmentDto)?.fileType?.match(/image\/.*/)) {
      return (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            minHeight: '300px',
            minWidth: '300px',
            display: 'grid',
            placeContent: 'center'
          }}>
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              if (currentTarget?.parentNode) {
                currentTarget.parentElement!.innerHTML = '<p>Preview Not Available</p>';
              }
              currentTarget.remove();
            }}
            loading="lazy"
            className={styles.modal_image}
          />
        </Box>
      );
    }

    /**
     * Fallback where no preview can be created
     */
    return (
      <Box
        sx={{
          width: '300px',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&::after': {
            content: '"No Preview Available"',
            position: 'absolute',
            color: 'black',
            transform: 'translateY(50px)'
          }
        }}>
        <ImageOff sx={{ fontSize: '3rem' }} />
      </Box>
    );
  };

  if (!file) {
    return null;
  }

  return (
    <Modal
      open={fileViewerOpen}
      onClose={closeFileViewer}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          minWidth: '300px',
          minHeight: '300px',
          maxHeight: '95%',
          maxWidth: '950px',
          overflowY: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          padding: '35px 10px 10px 10px'
        }}>
        <IconButton
          onClick={closeFileViewer}
          sx={{
            position: 'absolute',
            top: 1,
            right: 1,
            '&:hover': { borderRadius: '4px', backgroundColor: '#626679', color: '#fff' }
          }}>
          <Close />
        </IconButton>
        {buildPreview(file)}
        <Typography textAlign="center">{file.name}</Typography>
      </Box>
    </Modal>
  );
};

export default PreviewModal;
