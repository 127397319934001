import { Box, SxProps } from '@mui/material';
import { ImageOff } from 'mdi-material-ui';
import { useEffect, useRef } from 'react';
import * as Tiff from 'tiff.js';

/**
 * Tiff file type compatibility with browsers is still low (06/2023).
 * This component uses the tiff.js library and the FileReader API to generate
 * a canvas element which is capable of rendering the tiff file.
 */

interface RenderTiffProps {
  file: any;
  sx?: SxProps;
}

const RenderTiff = ({ file, sx = {} }: RenderTiffProps) => {
  const tiffContainer = useRef<HTMLDivElement>(null);

  /**
   * Append the generated canvas element to the tiff container ref.
   *
   * @param canvas
   * @returns void
   */
  const appendCanvas = (canvas: any): void => {
    if (tiffContainer?.current && canvas) {
      tiffContainer.current.append(canvas);
    }
  };

  /**
   * Append a default fallback if the canvas fails to load
   *
   * @returns void
   */
  const appendFallback = (): void => {
    if (tiffContainer?.current) {
      tiffContainer.current.innerHTML = '';
      tiffContainer.current.append(
        (
          <div>
            <ImageOff
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 998
              }}
            />
          </div>
        ) as any
      );
    }
  };

  useEffect(() => {
    /** Build image from file using FileReader API */
    let fileReader: FileReader;
    let isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = e => {
        /** Success */
        Tiff.initialize({ TOTAL_MEMORY: 16777216 * 10 });
        const tiff = new Tiff({ buffer: e.target!.result });

        const directoryIndex = tiff.countDirectory();

        for (let index = 0; index < directoryIndex; index++) {
          tiff.setDirectory(index);
          const canvas = tiff.toCanvas();
          appendCanvas(canvas);
        }
      };
      fileReader.onerror = _e => {
        /** Failure */
        appendFallback();
      };
      fileReader.readAsArrayBuffer(file);
    }

    /** Clean up the file reader */
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  return (
    <Box
      ref={tiffContainer}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '300px',
        minWidth: '300px',
        display: 'grid',
        placeContent: 'center',
        '& > canvas': { width: '100%', height: 'auto' },
        ...sx
      }}
    />
  );
};

export default RenderTiff;
