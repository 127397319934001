import { DataGridRef } from 'devextreme-react/data-grid';
import React, { RefObject } from 'react';

interface NewGridApiRefContextProps {
  state: {
    gridApiRef: RefObject<DataGridRef> | null;
  };
  dispatch: React.Dispatch<any>;
}

const NewGridApiRefContext = React.createContext<NewGridApiRefContextProps | null>(null);

const newGridRefReducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case 'setGridApiRef':
      return { ...state, gridApiRef: action.payload };
    case 'resetGridApiRef':
      return { ...state, gridApiRef: null };
    default:
      return state;
  }
};

const NewGridRefProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(newGridRefReducer, { gridApiRef: null });
  const value = { state, dispatch };

  return <NewGridApiRefContext.Provider value={value}>{children}</NewGridApiRefContext.Provider>;
};

const useNewGridApiRefContext = () => {
  const context = React.useContext(NewGridApiRefContext);
  if (context === null) {
    throw new Error('useNewGridApiRefContext must be used within a NewGridApiRefProvider');
  }

  return context;
};

export { NewGridRefProvider, useNewGridApiRefContext };
